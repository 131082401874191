import { Box, Heading, Paragraph } from 'theme-ui';

export default function SectionHeader({ children, ...rest }) {
  return (
    <Box as="header" variant="styles.header" {...rest}>
      {children}
    </Box>
  );
}

export function Title({ children, as = 'h1', variant = as, ...rest }) {
  if (!children) return null;
  return (
    <Heading variant={`styles.${variant}`} as={as} {...rest}>
      {children}
    </Heading>
  );
}

export function Subtitle({ children, variant = 'subtitle', ...rest }) {
  if (!children) return null;
  return (
    <Paragraph variant={`styles.${variant}`} {...rest}>
      {children}
    </Paragraph>
  );
}
