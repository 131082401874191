import { Paragraph } from 'theme-ui';
import Layout from '../components/Layout';
import Link from '../components/Link';
import OfferSection from '../components/OfferSection';
import SectionHeader, { Subtitle, Title } from '../components/SectionHeader';

export default function FourOhFour() {
  return (
    <Layout>
      <SectionHeader
        sx={{ marginTop: [5, null, 6], marginBottom: [3, null, 4] }}
      >
        <Subtitle sx={{ gridColumn: 'text' }}>Meine Diagnose:</Subtitle>
        <Title sx={{ gridColumn: 'text' }}>Hier ist nichts.</Title>
        <Paragraph>
          Vielleicht fangen wir von vorne an - starten wir hier:{' '}
          <Link to="/">Startseite</Link>.
        </Paragraph>
      </SectionHeader>

      <OfferSection />
    </Layout>
  );
}
