import { Box, Button, Heading, Paragraph } from 'theme-ui';
import useMainMenu from '../useMainMenu';
import LayoutGrid from './Layout/LayoutGrid';
import Link from './Link';

export default function OfferSection({ ...rest }) {
  return (
    <LayoutGrid
      sx={{
        gridColumn: 'full',
        backgroundColor: 'primary.200',
        paddingY: 5,
      }}
      {...rest}
    >
      <Box sx={{ gridColumn: 'content' }}>
        <Heading variant="styles.h2" sx={{ marginX: [3, 4], paddingBottom: 5 }}>
          Mein Angebot
        </Heading>
      </Box>
      <Box
        sx={{
          gridColumn: ['content', null, '2 / span 2'],
          marginX: [3, 4],
          marginBottom: 4,
        }}
      >
        <Heading variant="styles.h4">Psychotherapie</Heading>
        <SectionLinks slug="psychotherapie" sx={{ marginBottom: 4 }} />
        <Button as={Link} variant={`styles.block.accent`} to="/psychotherapie">
          Mehr erfahren
        </Button>
      </Box>
      <Box
        sx={{
          gridColumn: ['content', null, 'span 2'],
          marginX: [3, 4],
          marginBottom: 4,
        }}
      >
        <Heading variant="styles.h4">Beratung</Heading>
        <SectionLinks slug="beratung" sx={{ marginBottom: 4 }} />
        <Button as={Link} variant={`styles.block.accent`} to="/beratung">
          Mehr erfahren
        </Button>
      </Box>
      <Box
        sx={{
          gridColumn: ['content', null, 'span 2'],
          marginX: [3, 4],
          marginBottom: 4,
        }}
      >
        <Heading variant="styles.h4">Supervision</Heading>
        <SectionLinks slug="supervision" sx={{ marginBottom: 4 }} />
        <Button as={Link} variant="styles.block.accent" to="/supervision">
          Mehr erfahren
        </Button>
      </Box>
    </LayoutGrid>
  );
}

function SectionLinks({ slug, ...rest }) {
  const { items } = useMainMenu();
  return (
    <Paragraph sx={{ variant: 'text.small' }} {...rest}>
      {items
        .find(({ to }) => to === `/${slug}`)
        ?.items?.map(({ to, title }) => (
          <Link
            key={to}
            to={to}
            sx={{
              display: 'block',
              color: 'primary.800',
              ':hover': { color: 'primary.600' },
              marginY: 2,
            }}
          >
            {title}
          </Link>
        ))}
    </Paragraph>
  );
}
